import { useDispatch } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { AppDispatch } from "../../../../store/store";
import { updateCheckpointResult, fetchCheckpointResultExecute } from '../../../../store/control/checkpointsBlock.store';

import { Checkpoint, CheckpointResult, CheckpointResultStateEnum } from '../../../../interfaces/control/Checkpoint';
import { CheckpointExecute } from '../../../../interfaces/control/CheckpointExecute';
import UploadEnedisFile from "./meter/UploadEnedisFile";
import { useTheme} from '@mui/material';

interface propsDataTable {
    data: Checkpoint[]
}

interface Item{
    title: string,
    iconClass: React.ReactElement,
    iconColor: string,
    value: CheckpointResultStateEnum
}

export default function DataTableCheckpoint(props: propsDataTable) {

    const dispatch = useDispatch<AppDispatch>();
    const [searchParams] = useSearchParams();
    const theme = useTheme();

    const itemList:Item[] = [
        {
            title: 'Echec du test',
            iconClass: <ErrorOutlineIcon style={{color: theme.palette.checkpointResultFailed}} />,
            iconColor: 'checkpointResultFailed',
            value: CheckpointResultStateEnum.FAILED
        },
        {
            title: 'Non effectué',
            iconClass: <NotInterestedIcon/>,
            iconColor: 'checkpointResultFailed',
            value: CheckpointResultStateEnum.UNPERFORMED
        },
        {
            title: 'En cours',
            iconClass: <HourglassBottomIcon style={{color: theme.palette.checkpointResultInProgress}}/>,
            iconColor: 'checkpointResultInProgress',
            value: CheckpointResultStateEnum.IN_PROGRESS
        },
        {
            title: 'Conforme',
            iconClass: <CheckCircleOutlineIcon style={{color: theme.palette.checkpointResultOk}}/>,
            iconColor: 'checkpointResultOk',
            value: CheckpointResultStateEnum.OK
        },
        {
            title: 'Incertain',
            iconClass: <WarningAmberIcon style={{color: theme.palette.checkpointResultUncertain}}/>,
            iconColor: 'checkpointResultUncertain',
            value:CheckpointResultStateEnum.UNCERTAIN
        },
        {
            title: 'Non conforme',
            iconClass: <HighlightOffIcon style={{color: theme.palette.checkpointResultKo}}/>,
            iconColor: 'checkpointResultKo',
            value: CheckpointResultStateEnum.KO
        }
    ]


    function numberSuccesCheckpoint(): number{
        let counterSucces = 0;
        props.data.forEach((checkpoint: Checkpoint) => {
            if(checkpoint.result?.state === CheckpointResultStateEnum.OK) counterSucces++
        })
        return counterSucces;
    }

    const handleUpdateCheckpointResult = (e: string | null, result: CheckpointResult | null): void =>{
        if(e && result){
            const temporayResult = {...result}
            temporayResult.state = CheckpointResultStateEnum[e as keyof typeof CheckpointResultStateEnum]
            dispatch(updateCheckpointResult(temporayResult))
        }
    }

    const handleCheckpointResultExecute = (result: CheckpointResult | null): void => {
        const plantId = searchParams.get('plant_id')
        if(result &&  plantId){
            const checkpointExecuteValue = {} as CheckpointExecute;
            checkpointExecuteValue.plantId = parseInt(plantId);
            checkpointExecuteValue.checkpointResultId = result.id;
            dispatch(fetchCheckpointResultExecute(checkpointExecuteValue))
        }
    }

    const getTitleCheckpointResultExecute = (checkpoint: Checkpoint | null): string => {
        if(checkpoint && checkpoint.result){
            if(checkpoint.result.state === CheckpointResultStateEnum.IN_PROGRESS){
                return "Le point de contrôle est en cours de traitement, patientez jusqu'à la fin."
            }
            return ''
        }
        return 'Vous devez calculer tous les points de contrôle au moins une fois.'
    }

    function createMenuItem(checkpoint: Checkpoint): React.ReactElement[]{
        let menuItem:React.ReactElement[] = [];
        const nonEditableStates = [CheckpointResultStateEnum.FAILED, CheckpointResultStateEnum.IN_PROGRESS, CheckpointResultStateEnum.UNPERFORMED];
        if (checkpoint.result !== null  &&  checkpoint.result.state !== null  &&
            nonEditableStates.includes(checkpoint.result.state)) {
            const item = itemList.find((i) => i.value === checkpoint.result?.state);
            menuItem = [
                <MenuItem value={item?.value} key={item?.value}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item?.iconClass}
                        <div style={{marginLeft: 50}}>{item?.title}</div>
                    </div>
                </MenuItem>
            ];
        } else {
            itemList.forEach((item: Item) => {
                if (checkpoint.result !== null  &&
                    (item.value === CheckpointResultStateEnum.UNPERFORMED ||
                     item.value === CheckpointResultStateEnum.FAILED ||
                     item.value === CheckpointResultStateEnum.IN_PROGRESS)
                ) {
                    return;
                }
                menuItem.push(
                    <MenuItem value={item.value} key={item.value}>
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                            {item.iconClass}
                            <div style={{marginLeft: 50}}>{item.title}</div>
                        </div>
                    </MenuItem>
                );
            });
        }
        return menuItem;
    }

    function getDate(checkpointResult: CheckpointResult | null): string {

        if (checkpointResult  &&  checkpointResult.date) {
            return (new Date(checkpointResult.date)).toLocaleDateString();
        }

        return 'N/A';
    }

    return (
        <TableContainer style={{ marginTop: 10 }} component={Paper} className='custom-card'>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <caption data-testid="numberOfValidatesCaption">{numberSuccesCheckpoint()} des { props.data.length } points de contrôles sont validés</caption>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Contrôle</TableCell>
                        <TableCell align="center">Résultat</TableCell>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">État</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {props.data.map((row) => (
                    <TableRow data-testid={`${row.name}Row`} key={row.id}>
                        <TableCell data-testid={`${row.name}Control`} component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell data-testid={`${row.name}Result`} align="center" style={{maxWidth: '140px'}}>
                            <Tooltip title={(row.result) ? row.description : ''}>
                                <span>
                                    {(row.result) ? row.result.result: 'N/A'}
                                </span>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="center" data-testid={`${row.name}Date`}>
                            {getDate(row.result)}
                        </TableCell>
                        <TableCell style={{padding: 0}} align="center">
                            <FormControl sx={{ m: 2, minWidth: 250 }} size="small" style={{marginTop: 20}}>
                                <InputLabel id="inputLabelStateCheckpointLabel">État du point de contrôle</InputLabel>
                                <Select
                                    data-testid={`${row.name}State`}
                                    labelId="selectStateCheckpointLabel"
                                    id="selectStateCheckpoint"
                                    data-cy="cypressSelectStateCheckpoint"
                                    value={(row.result) ? (row.result.state) : CheckpointResultStateEnum.UNPERFORMED}
                                    disabled={(row.result) ? ((row.result.state === CheckpointResultStateEnum.IN_PROGRESS || row.result.state === CheckpointResultStateEnum.FAILED)) : true}
                                    label="État du point de contrôle"
                                    color='primary'
                                    onChange={(e) => handleUpdateCheckpointResult(e.target.value, row.result)}
                                >
                                    {createMenuItem(row)}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell align="center">
                            <Tooltip title={getTitleCheckpointResultExecute(row)}>
                                <span>
                                {
                                    (row.name === 'Cohérence des courbes')
                                    ?
                                    <UploadEnedisFile
                                    isLoading={(row.result) ? ((row.result.state === CheckpointResultStateEnum.IN_PROGRESS)) : false}/>
                                    :
                                    <LoadingButton
                                        data-testid={`${row.name}Action`}
                                        loading={(row.result) ? ((row.result.state === CheckpointResultStateEnum.IN_PROGRESS)) : false}
                                        variant="outlined"
                                        startIcon={<RestartAltIcon />}
                                        onClick={()=>{ handleCheckpointResultExecute(row.result) }}
                                    >
                                        Calculer
                                    </LoadingButton>
                                }
                                </span>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}