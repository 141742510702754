
export enum PlantStatus {
    ALL = 'ALL',
    TO_CONTROL = 'TO_CONTROL',
    COMPLIANT = 'COMPLIANT',
    NOT_COMPLIANT = 'NOT_COMPLIANT'
}


type Plant = {
    id: number;
    name: string;
    agency_name: string;
    formatted_address: string;
    status: Exclude<PlantStatus, PlantStatus.ALL>;
    serial_number: string;
    operation_date: string;
    last_control_date: string;
    total_checkpoint_count: number;
    ok_checkpoint_count: number;
}
export default Plant;