import { createTheme, Theme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

declare module '@mui/material/styles' {
    interface Theme {
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
    }
    interface Palette {
        noData: string;
        scadaBackground: string;
        cardGrey: string;
        toolbarBackground: string;
        inverterProductionCurves: string[];
        immersionHeaterConsumptionCurves : string[];
        trackerWindCurves: string[];
        trackerElevationCurves: string[];
        trackerExpectedElevationCurve: string;
        trackerAzimuthCurves: string[];
        trackerExpectedAzimuthCurve: string;
        eventChartLabel: string;
        plantProductionCurve: string;
        plantConsumptionCurve: string;
        plantWindCurve: string;
        windThresoldCurve: string;
        snowCurve: string;
        trackerAlarmCurve: string;
        immersionHeaterAlarmCurve: string;
        soutirageEnedisCurve: string;
        notSynchronised: string;
        secondaryFont: string;
        neutralColor: string;
        checkpointResultFailed: string;
        checkpointResultInProgress: string;
        checkpointResultOk: string;
        checkpointResultUncertain: string;
        checkpointResultKo: string;
      }
      interface PaletteOptions {
        noData: string;
        scadaBackground: string;
        toolbarBackground: string;
        inverterProductionCurves: string[];
        immersionHeaterConsumptionCurves : string[];
        trackerWindCurves: string[];
        trackerElevationCurves: string[];
        trackerExpectedElevationCurve: string;
        trackerAzimuthCurves: string[];
        trackerExpectedAzimuthCurve: string;
        eventChartLabel: string;
        plantProductionCurve: string;
        plantConsumptionCurve: string;
        plantWindCurve: string;
        windThresoldCurve: string;
        snowCurve: string;
        trackerAlarmCurve: string;
        immersionHeaterAlarmCurve: string;
        soutirageEnedisCurve: string;
        notSynchronised: string;
        secondaryFont: string;
        neutralColor: string;
      }
}

const baseTheme = (mode: string) => {
    return{
        palette: {
            mode: mode as PaletteMode,
            primary: {
                main: '#35ba07',
                light: '#808eba'
            },
            secondary: {
                main: '#044589',
                light: '#7ecbb6'
            },
            success : {
                main: '#35ba07'
            },
            warning : {
                main: '#F7B32B'
            },
            error: {
                main: '#D72638'
            },
            noData : '#8e8e8e',
            toolbarBackground: '#eaeaea',
            scadaBackground: '#ffffff',
            cardGrey: '#F5F5F5',
            inverterProductionCurves: ['#006d2c', '#238b45', '#41ae76', '#66c2a4', '#99d8c9', '#ccece6', '#e5f5f9'],
            immersionHeaterConsumptionCurves: ['#ece7f2', '#d0d1e6', '#a6bddb', '#3690c0', '#74a9cf', '#0570b0', '#045a8d'],
            trackerWindCurves: ['#54424B', '#8A496F', '#915F89', '#9675A1', '#9C8BB7', '#A2A1C9', '#ACB7DA'],
            trackerElevationCurves: ['#BFAB25', '#8EA427', '#5D9A34', '#1D8E43', '#008052', '#00715E', '#005247'],
            trackerExpectedElevationCurve: '#89A6FB',
            trackerAzimuthCurves: ['#BFAB25', '#8EA427', '#5D9A34', '#1D8E43', '#008052', '#00715E', '#005247'],
            trackerExpectedAzimuthCurve: '#89A6FB',
            eventChartLabel: '#000000',
            plantProductionCurve: '#ffd500',
            plantConsumptionCurve: '#05468c',
            plantWindCurve: '#BCD2EE',
            windThresoldCurve: '#D72638',
            snowCurve: '#DBDFAC',
            trackerAlarmCurve: '#BFAB25',
            immersionHeaterAlarmCurve: '#BFAB25',
            soutirageEnedisCurve: '#009870',
            notSynchronised: '#236AB6',
            secondaryFont: 'rgb(0, 0, 0, 0.4)',
            neutralColor: '#808080',
            checkpointResultFailed: 'red',
            checkpointResultInProgress: 'gray',
            checkpointResultOk: 'green',
            checkpointResultUncertain: '#3778bd',
            checkpointResultKo: 'orange',
        },
        typography: {
            fontFamily: 'Montserrat'
        },
    }
}

const overrides = {
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: () =>
                    getTheme('light').unstable_sx({
                        backgroundColor: '#044589',
                    },
                )
            }
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: () =>
                    getTheme('light').unstable_sx({
                        backgroundColor: '#35ba07',
                        color: 'white',
                        width: '100%',
                        bottom: 0,
                        position: 'fixed',
                        alignItems: 'center',
                        display: 'flex'
                    },
                )
            }
        },
        MuiFormControl:{
            styleOverrides: {
                root: {
                    marginBottom:'20px!important'
                },
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow:'None'
                },
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding:0,
                },
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding:0
                },
            }
        },
        MuiList:{
            styleOverrides: {
                root: {
                    paddingTop:'4px!important',
                    paddingBottom:'4px!important'
                },
            }
        }
    }
}

const getTheme = (mode: string): Theme => {
    return createTheme({
    ...baseTheme(mode),
    ...overrides
});
}

export default getTheme;