import {useState, useEffect, useMemo, ComponentProps} from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { useTheme } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";

type SearchedProps = {
  value: string;
  width: string;
  placeholder?: string;
  notifyOnClear: boolean;
  onChange: (value: string) => void;
  helper?: string;
} & Omit<ComponentProps<typeof TextField>, 'onChange'>;

export default function SearchTextField(props: SearchedProps) {

  const [searchValue, setSearchValue] = useState<string>("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const search$ = useMemo(() => new BehaviorSubject(""), []);

  const clear = () => {
    setSearchValue("")
    if(props.notifyOnClear) props.onChange("")
  }

  useEffect(() => {
    search$.next(searchValue);
  }, [searchValue, search$]);

  useEffect(() => {
    if(searchValue.length >= 3){
      const subscription = search$
      .pipe(debounceTime(400))
      .subscribe(() => {props.onChange(searchValue)});
      return () => subscription.unsubscribe();
    }
  }, [searchValue, search$]);

  return (
    <TextField
        size={props.size}
        id="searchInput"
        style={{ marginTop: 15, width: (isMobile) ? '100%' : props.width}}
        label="Rechercher"
        value={searchValue}
        placeholder={props.placeholder}
        helperText={props.helper}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
        title={props.title}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            (searchValue !== "") &&
            <InputAdornment position="end">
              <IconButton onClick={clear}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
    />
  );
}