import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import dayjs, {type Dayjs} from "dayjs";

import Grid from "@mui/material/Grid";

import {PlantStatus} from "../../../../interfaces/control/Plant";
import {AppDispatch, RootState} from "../../../../store/store";
import {fetchAgencies} from "../../../../store/form/agencies.store";
import ControlledSelect from "../../../../component/form/ControlledSelect";
import {PlantFilters} from "../../../../interfaces/control/PlantFilters";
import AppDatepicker from "../../../../component/AppDatepicker";
import SearchTextField from "../../../../component/form/SearchTextField";


type PlantsToControlFiltersProps = {
    onFilterChange: (filters: PlantFilters) => void;
}

const PlantsToControlFilters = ({onFilterChange}: PlantsToControlFiltersProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamStatus = searchParams.get('status') as PlantStatus;
    const searchParamAgency = searchParams.get('agency');
    const searchParamBegin = searchParams.get('begin');
    const searchParamEnd = searchParams.get('end');
    const searchParamSearch = searchParams.get('search');

    const {control, watch, setValue} = useForm<PlantFilters>({
        defaultValues: {
            status: searchParamStatus !== null ? searchParamStatus : PlantStatus.ALL,
            agency: searchParamAgency !== null ? parseInt(searchParamAgency) : -1,
            begin: searchParamBegin !== null ? searchParamBegin : dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
            end: searchParamEnd !== null ? searchParamEnd : dayjs().subtract(6, 'day').format('YYYY-MM-DD')
        }
    });
    const dispatch = useDispatch<AppDispatch>();
    const agenciesList = useSelector((state: RootState) => state.agencies.data);
    const [search, setSearch] = useState<string>(searchParamSearch !== null ? searchParamSearch : '');

    useEffect(() => {
        if(agenciesList.length === 0) {
            dispatch(fetchAgencies())
        }
    }, []);

    const status = watch('status');
    const agency = watch('agency');
    const begin = watch('begin');
    const end = watch('end');

    useEffect(() => {
        onFilterChange({
            search: search,
            status: status,
            agency: agency,
            begin: begin,
            end: end,
        });
        setSearchParams(
            () => {
                const params = new URLSearchParams();
                if (search !== '') {
                    params.append('search', search);
                }
                if (status !== PlantStatus.ALL) {
                    params.append('status', status);
                }
                if (agency !== -1) {
                    params.append('agency', `${agency}`);
                }
                if (begin !== '' && begin !== null) {
                    params.append('begin', `${begin}`);
                }
                if (end !== '' && end !== null) {
                    params.append('end', `${end}`);
                }
                return params;
            }
        );
    }, [search, status, agency, begin, end]);

    return (
        <Grid container direction={{xs: 'column', md: 'column', lg: 'row'}} spacing={2}>
            <Grid item xs={12} sm={12} md={2} lg={2}>
                <SearchTextField
                    value={search}
                    notifyOnClear={true}
                    onChange={(value) => setSearch(value)}
                    width={'100%'}
                    placeholder={"Par nom, addresse, numéro de série ou id..."}
                    title={"Rechercher par nom, addresse, numéro de série ou id"}
                    helper="La recherche doit contenir au moins 3 caractères."
                    autoComplete={'off'}
                />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
                <ControlledSelect
                    name={'status'}
                    style={{marginTop: 15}}
                    label={'Statut'}
                    control={control}
                    variant={'outlined'}
                    fullWidth
                    id={'status'}
                    options={[
                        {value: PlantStatus.ALL, label: 'Tous les statuts'},
                        {value: PlantStatus.TO_CONTROL, label: 'A controler'},
                        {value: PlantStatus.COMPLIANT, label: 'Conforme'},
                        {value: PlantStatus.NOT_COMPLIANT, label: 'Non conforme'}
                    ]}
                />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
                <ControlledSelect
                    name={'agency'}
                    style={{marginTop: 15}}
                    label={'Agence'}
                    id={'agency'}
                    control={control}
                    fullWidth
                    variant={'outlined'}
                    options={[
                        {value: -1, label: 'Toutes les agences'},
                        ...agenciesList.map(agency => ({value: agency.id, label: agency.name}))
                    ]}
                />
            </Grid>

            <Grid item xs={12} md={2} lg={2} style={{ marginTop: 15 }}>
                <AppDatepicker
                    label={'Date de mise en route'}
                    value={[
                        begin !== null ? dayjs(begin) : null,
                        end !== null ? dayjs(end) : null
                    ]}
                    onChange={(dates: [Dayjs | null, Dayjs | null]) => {
                        if (dates[0] !== null) {
                            setValue('begin', dates[0].format('YYYY-MM-DD'));
                        } else {
                            setValue('begin', null);
                        }
                        if (dates[1]) {
                            setValue('end', dates[1].format('YYYY-MM-DD'));
                        } else {
                            setValue('end', null);
                        }
                    }}
                    allowEmpty={[true, true]}
                    style={{ backgroundColor: "inherit", width: 280, height: 55}}
                    placeholder={['Entre le...', 'et le...']}
                />
            </Grid>
        </Grid>
    );
}

export default PlantsToControlFilters;