import Plant, {PlantStatus} from "../interfaces/control/Plant";
import Tooltip from '@mui/material/Tooltip';

type PlantStatusTagProps = {
    status: Plant['status'];
}


const PlantStatusTag = ({status}: PlantStatusTagProps) => {
    const statuses = {
        [PlantStatus.TO_CONTROL]: {
            "color": 'grey',
            "description": 'A contrôler : Aucun contrôle effectué, ou au moins un point de contrôle n\'a pas encore été déclaré conforme ou non conforme',
        },
        [PlantStatus.COMPLIANT]: {
            "color": 'green',
            "description": 'Conforme : Tous les points de contrôles exécutés sont conformes',
        },
        [PlantStatus.NOT_COMPLIANT]: {
            "color": 'orange',
            "description": 'Non conforme : Au moins un point de contrôle est non conforme',
        }
    };
    return (
        <Tooltip title={statuses[status].description}>
            <div style={{width: '16px', height: '16px', borderRadius: '16px', backgroundColor: statuses[status].color}}></div>
        </Tooltip>
    )
}

export default PlantStatusTag;